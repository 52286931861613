export const markerStyle = {
  color: "grey",
  radius: 6,
  opacity: 0.6,
  selectedRadius: 8,
  selectedColors: [
    '#30a2da',
    '#fc4f30',
    '#e5ae38',
    '#6d904f',
    '#b96db8',
    '#ff9e27',
    '#56cc60',
    '#52d2ca',
    '#52689e',
    '#545454',
    '#9fe4f8'
  ]
};
export const graphStyle = {
  meanOfSelectedColor: 'black'
};