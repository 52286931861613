import { StateStore } from './state'

export function updateWho(state: StateStore) {
  if (state.date) {
    const year = state.date.substr(0, 4);
    if (state.who.hasOwnProperty(year)) {
      const whoElement = document.getElementById('who');
      if (whoElement) {
        const yearData = state.who[year];
        whoElement.innerHTML = `<h1>${year}</h1>
        <table>
        <tr><th></th>     <th>Annual average</th>                                                     <th>Number of days exceeding 24h-average WHO guideline</th>
        <tr><th>PM10 </th><td><strong class="whowarning">${yearData['PM10'].toFixed(1)} μg / m³</strong> (WHO guideline: 15 μg / m³)</td> 
          <td><strong class="whowarning">${yearData['days_pm10_over_24h_guideline']}</strong> (WHO guideline: 45 μg / m³)</td></tr>
        <tr><th>PM2.5</th><td><strong class="whowarning">${yearData['PM25'].toFixed(1)} μg / m³</strong> (WHO guideline: 5 μg / m³)</td>
          <td><strong class="whowarning">${yearData['days_pm25_over_24h_guideline']}</strong> (WHO guideline: 15 μg / m³)</td></tr>
        </table>`;
      }
    } 
  }
}

export function loadWhoData(state: StateStore) {
  fetch('yearly_averages.json')
    .then((res) => res.json())
    .then((result) => {
      state.who = result;
      updateWho(state);
    })
    .catch(console.error);
}
