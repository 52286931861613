import { CircleMarker, Marker } from "leaflet";
import { throws } from "assert";
import { thresholdFreedmanDiaconis } from "d3-array";

export type Color = string
export type SensorId = string
export type StationId = string
export type PlotType = "all" | "average"
export const PLOT_TYPES: PlotType[] = ["all", "average"];

export type Who = {
  "PM25": number,
  "PM10": number,
  "days_pm25_over_24h_guideline": number,
  "days_pm10_over_24h_guideline": number
}

export type WhoYearly = { [key: string]: Who }

export class StateStore {
  private _date: string | null
  private _sensors: Map<SensorId, Color>
  private _markers: Map<SensorId, CircleMarker>
  private _onChange: (state: StateStore) => any
  private _colors: Color[];
  private _station: StationId | null;
  stationLabel: string;
  private _stationMarkers: Map<StationId, Marker>
  private _plotType: PlotType
  private _activeSensors: SensorId[]
  private _who: WhoYearly

  constructor(onChange: (state: StateStore) => any, colors: Color[], sensors?: SensorId[]) {
    this._plotType = "all";
    this._date = null;
    this._colors = colors;
    this._station = null;
    this.stationLabel = "";
    this._stationMarkers = new Map;
    this._sensors = new Map;
    if (sensors) {
      for (const sensor of sensors) {
        this._sensors.set(sensor, this.nextColor(sensor));
      }
    }
    this._markers = new Map;
    this._activeSensors = [];
    this._who = {};
    this._onChange = onChange;
  }

  private changed() {
    window.location.hash = "sensors=" + Array.from(this.sensors.keys()).map((id) => id.toString()).join(',')
    this._onChange(this);
  }

  get date(): string | null {
    return this._date;
  }

  set date(newDate: string | null) {
    this._date = newDate;
    this.changed();
  }

  get sensors(): Map<SensorId, Color> {
    return this._sensors;
  }

  set station(newStation: StationId | null) {
    this._station = newStation;
    this.changed()
  }

  get station(): StationId | null {
    return this._station;
  }

  public toggleSensor(id: SensorId, color?: Color) {
    if (!this._sensors.has(id) && color) {
      this._sensors.set(id, color);
    } else {
      this._sensors.delete(id);
    }
    this.changed();
  }

  get markers(): Map<SensorId, CircleMarker> {
    return this._markers;
  }

  public addMarker(id: SensorId, marker: CircleMarker) {
    this._markers.set(id, marker);
  }

  get stationMarkers(): Map<StationId, Marker> {
    return this._stationMarkers;
  }

  public addStationMarker(id: StationId, marker: Marker) {
    this._stationMarkers.set(id, marker);
  }

  set plotType(newPlotType: PlotType) {
    this._plotType = newPlotType;
    this.changed();
  }

  get plotType(): PlotType {
    return this._plotType;
  }

  public nextColor(id: SensorId): Color {
    return this._colors[parseInt(id) % this._colors.length];
  }

  get activeSensors(): SensorId[] {
    return this._activeSensors;
  }

  set activeSensors(newActiveSensors: SensorId[]) {
    this._activeSensors = newActiveSensors;
  }

  set who(newWho: WhoYearly) {
    this._who = newWho;
  }

  get who(): WhoYearly {
    return this._who;
  }
}

export function parseLocation(location: Location): {sensors: SensorId[]} {
  const hash = location.hash.substr(1);
  const parts = hash.split('=');
  const sensorIds = parts[0] == "sensors" ? parts[1].split(',') : [];
  return {sensors: sensorIds.map((id) => id)};
}
