import { VisualizationSpec } from "vega-embed"
import { NormalizedLayerSpec, GenericLayerSpec, GenericUnitSpec } from "vega-lite/build/src/spec";
import { CompositeEncoding } from "vega-lite/build/src/compositemark";
import { AnyMark } from "vega-lite/build/src/mark";
import { Color, SensorId, StationId } from './state'

export function calendarSpec(data: any): VisualizationSpec {
  return {
    "$schema": "https://vega.github.io/schema/vega-lite/v4.0.json",
    "title": "Select a date",
    "width": 600,
    "data": data,
    "transform": [
      { "calculate": "year(datum.date)", "as": "year" },
      { "filter": { "selection": "year" } }
    ],
    "mark": "rect",
    "encoding": {
      "x": {
        "field": "date", "type": "ordinal", "timeUnit": "date",
        "axis": {
        //   "labelAlign": "left",
        //   "tickCount": 31,
          "title": "Day of month"
        }
      },
      "y": {
        "field": "date",
        "type": "ordinal", "timeUnit": "yearmonth",
        "axis": {
          "title": "Month"
        }
      },
      "color": { "field": "PM25", "type": "quantitative", "title": "PM2.5" },
      "opacity": {
        "condition": {
          "selection": "dt",
          "value": 1
        },
        "value": 0.4
      }
    },
    "selection": {
      "dt": { "type": "single" },
      "year": {
        "type": "single",
        "fields": ["year"],
        "bind": { "year": { "input": "select", "options": [2018, 2019, 2020, 2021, 2022, 2023, 2024], "name": "Year", "element": "#calendarControls" } },
        "init": { "year": 2024 }
      }
    }
  };
}

function layerForSensor(sensorId: string, color: string): GenericUnitSpec<CompositeEncoding, AnyMark> {
  return {
    "mark": "line",
    "encoding": {
      "x": { "field": "timestamp", "type": "temporal" },
      "y": { "field": sensorId, "type": "quantitative" },
      "color": { "value": color }
    }
  }
}

function ircelineLayer(stationId: string, date: string): GenericUnitSpec<CompositeEncoding, AnyMark> {
  return {
    "data": {
      "url": `irceline/${date}_${stationId}_pm25.json`,
    },  
    "mark": {
      "type": "line"
    },
    "transform": [
      { "calculate": "\"IRCELINE\"", "as": "measure" }
    ],
    "encoding": {
      "x": { "field": "timestamp", "type": "temporal" },
      "y": { "field": "value", "type": "quantitative" },
      "color": {
        "field": "measure", 
        "type": "nominal", 
        "sort": ["median", "who_yearly_pm25_limit", "who_24h_pm25_limit", "IRCELINE"]
      }
    }
  }
}

export function graphSpec(date: string, data: any, sensors: Map<SensorId, Color>, stationId: StationId | null): VisualizationSpec {
  const layers: GenericUnitSpec<CompositeEncoding, AnyMark>[] = [
    {
      "mark": {
        "type": "area",
        "color": "#ccc",
        "opacity": 0.5
      },
      "encoding": {
        "x": { "field": "timestamp", "type": "temporal", "axis": { "title": "Time" } },
        "y": { "field": "10%", "type": "quantitative", "axis": { "title": "PM 2.5 (µg / m³)" } },
        "y2": { "field": "90%" }
      }
    },
    {
      "transform": [
        {"filter": {"field": "measure", "equal": "median"}}
      ],
      "mark": {
        "type": "line",
        "strokeWidth": 3
      },
      "encoding": {
        "x": { "field": "timestamp", "type": "temporal" },
        "y": { "field": "value", "type": "quantitative" },
        "color": { 
          "field": "measure",
          "type": "nominal",
          "sort": ["median", "who_yearly_pm25_limit", "who_24h_pm25_limit", "IRCELINE"],
          "legend": {
            "labelExpr": "datum.label == 'median' ? 'Median' : (substring(datum.label, 0, 4) == 'who_' ? (substring(datum.label, 4, 7) == '24h' ? 'WHO guideline 24-hour mean' : 'WHO guideline annual mean') : datum.label)"
          }
        }
      }
    },
    {
      "transform": [
        {"filter": {"field": "measure", "equal": "who_yearly_pm25_limit"}}
      ],
      "mark": {
        "type": "rule",
        "strokeWidth": 1,
        "strokeDash": [8, 8]
      },
      "encoding": {
        "y": { "field": "value", "type": "quantitative" },
        "color": { 
          "field": "measure",
          "type": "nominal",
          "sort": ["median", "who_yearly_pm25_limit", "who_24h_pm25_limit", "IRCELINE"]
        }
      }
    },
    {
      "transform": [
        {"filter": {"field": "measure", "equal": "who_24h_pm25_limit"}}
      ],
      "mark": {
        "type": "rule",
        "strokeWidth": 2,
        "strokeDash": [8, 8]
      },
      "encoding": {
        "y": { "field": "value", "type": "quantitative" },
        "color": { 
          "field": "measure",
          "type": "nominal",
          "sort": ["median", "who_yearly_pm25_limit", "who_24h_pm25_limit", "IRCELINE"]
        }
      }
    }
  ]
  sensors.forEach((color, sensorId) => {
    if (sensorId) { 
      layers.push(layerForSensor(sensorId, color));
    }
  });
  if (stationId) {
    layers.push(ircelineLayer(stationId, date));
  }
  
  return {
    "$schema": "https://vega.github.io/schema/vega-lite/v4.0.json",
    "title": `Air quality in Belgium on ${date}: PM 2.5`,
    "width": "container",
    "height": 480,
    "data": data,
    "transform": [
      { "calculate": "5", "as": "who_yearly_pm25_limit" },
      { "calculate": "15", "as": "who_24h_pm25_limit" },
      {"fold": ["median", "who_yearly_pm25_limit", "who_24h_pm25_limit"], "as": ["measure", "value"]}
    ],
    "layer": layers,
    "resolve": {
      "scale": { "y": "shared" },
      "axis": { "y": "shared" },
      "legend": {"color": "shared"}
    },
    "config": {
      "legend": {
        "orient": "bottom"
      }
    }
  };
}
