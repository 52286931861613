import vegaEmbed, { Result } from "vega-embed"
import { ValuesData } from "vega";
const _ = require('./node_modules/leaflet/dist/leaflet.css');
import L, { CircleMarker, Marker } from "leaflet";
import * as d3Array from 'd3-array';

import { calendarSpec, graphSpec } from './specs'
import { PlotType, StateStore, SensorId, StationId, parseLocation } from './state'
import { graphStyle, markerStyle } from './style'
import { updateWho, loadWhoData } from './who'

const map = L.map("map", { minZoom: 7 }).setView(L.latLng(50.62, 4.39), 8);
L.tileLayer(
  'https://api.maptiler.com/maps/bright/{z}/{x}/{y}.png?key=wrAe0qa66QxnrxMRjz7x',
  {
    tileSize: 512,
    zoomOffset: -1, 
    attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>'
  }
).addTo(map);

const IsNumericString = new RegExp(/^\d+$/);

type Sensor = {
  id: number,
  longitude: number,
  latitude: number
};

type Station = {
  id: number,
  label: string,
  coordinates: [number, number],
  pm10: string | null,
  pm25: string | null,
  temperature: string | null,
  humidty: string | null,
  wind_direction: string | null,
  wind_speed: string | null
}


function render(state: StateStore): any {
  updateGraph(state);
  updateMarkers(state);
  updateStation(state);
  updateSensors(state);
}

function updateGraph(state: StateStore): any {
  if (state.date) {
    fetch(`pm25/${state.date}.json`)
      .then(res => res.json())
      .then((result: any[]) => {
        if (result.length > 0) {
          state.activeSensors = Object.keys(result[0]).filter(key => IsNumericString.test(key));
        } else {
          state.activeSensors = [];
        }
        updateMarkers(state);
        updateSensors(state);
        const dateInfoElement = document.getElementById("dateinfo");
        if (dateInfoElement) {
          dateInfoElement.innerHTML = `${state.activeSensors.length} sensors logged data on ${state.date}`
        }
        if (state.date) {
          let sensors: Map<string, string>;
          if (state.plotType === "average") {
            result = result.map(datapoint => {
              const selected = Object.entries(datapoint)
                .filter(([key, value]) => Array.from(state.sensors.keys()).includes(key) && typeof value === 'number')
                .map(([_, value]) => value) as number[];
              return {
                "timestamp": datapoint['timestamp'],
                "median": datapoint['median'],
                "10%": datapoint['10%'],
                "90%": datapoint['90%'],
                "selected": d3Array.mean(selected)
              };
            });
            sensors = new Map();
            sensors.set("selected", graphStyle.meanOfSelectedColor);
          } else {
            sensors = state.sensors;
          }
          const data = {
            "name": "data",
            "values": result
          };
          vegaEmbed("#graph", graphSpec(state.date, data, sensors, state.station), { actions: false })
            .catch(console.error);
        }
      });
  }
}

function updateMarkers(state: StateStore): any {
  state.markers.forEach((marker: CircleMarker, sensorId: SensorId) => {
    if (state.activeSensors.includes(sensorId)) {
      if (!map.hasLayer(marker)) {
        marker.addTo(map);
      }
      const sensorColor = state.sensors.get(sensorId);
      if (sensorColor) {
        marker.setStyle({ color: sensorColor, fillOpacity: 1.0 });
        marker.setRadius(markerStyle.selectedRadius);
      } else {
        marker.setStyle({ color: markerStyle.color, fillOpacity: markerStyle.opacity });
        marker.setRadius(markerStyle.radius);
      }
    } else {
      if (map.hasLayer(marker)) {
        console.log("Removing sensor")
        map.removeLayer(marker);
        state.sensors.delete(sensorId);
      }
    }
  });
  state.stationMarkers.forEach((marker: Marker, stationId: StationId) => {
    if (stationId == state.station) {
      marker.setOpacity(1.0);
    } else {
      marker.setOpacity(0.4);
    }
  });
}

function updateStation(state: StateStore): any {
  const station = document.getElementById("selectedStation");
  if (station) {
    if (state.station) {
      station.innerHTML = `<li><img src="cloud.png" height="14"> ${state.stationLabel}</li>`
    } else {
      station.innerHTML = ""
    }
  }
}

function updateSensors(state: StateStore): any {
  const selected = document.getElementById("selectedSensors");
  if (selected) {
    selected.innerHTML = Array.from(state.sensors.entries())
      .filter(sensor => sensor[1]) // parsing of the urls is broken, filter out undefined sensor
      .map((sensor) => `<li><svg width="14" height="14"><circle cx="7px" cy="7px" r="6px" fill="${sensor[1]}"/></svg> ${sensor[0]}</li>`).join("\n");
  }
}

const stateInUrl = parseLocation(window.location);
const state = new StateStore(render, markerStyle.selectedColors, stateInUrl.sensors);


let initialData: ValuesData = {
  "name": "calendar-data",
  "values": [],
  "format": {
    "parse": {
      "date": "date"
    }
  }
};

function initCalendar(data: any): Promise<[Result, any]> {
  initialData.values = data;
  return vegaEmbed("#calendar", calendarSpec(initialData), { actions: false }).then((result) => [result, data]);
}

fetch("calendar.json")
  .then((res) => res.json())
  .then(initCalendar)
  .then((result) => {
    const calendarView = result[0].view;
    const data = result[1];
    state.date = data[data.length - 1].date;
    updateWho(state);
    calendarView.addSignalListener("dt", (_name, value) => {
      if (value['_vgsid_']) {
        state.date = data[value['_vgsid_'].values().next().value - 1].date;
        console.log(value['_vgsid_']);
        console.log(data)
        updateWho(state);
      }
    });
  })
  .catch(console.error);

fetch("sensors.json")
  .then((res) => res.json())
  .then((sensors) => {
    sensors.forEach((sensor: Sensor) => {
      const sensorId = sensor.id.toString();
      const marker = L.circleMarker([sensor.latitude, sensor.longitude], { radius: markerStyle.radius, stroke: false, fillOpacity: markerStyle.opacity, color: markerStyle.color })
        .on("click", (e) => {
          const color = state.nextColor(sensorId);
          state.toggleSensor(sensorId, color);
        });
      // .addTo(map);
      state.addMarker(sensorId, marker);
    });
    render(state);
  })
  .catch(console.error);

fetch("stations_irceline.json")
  .then((res) => res.json())
  .then((stations) => {
    stations.forEach((station: Station) => {
      const stationId = station.id.toString();
      if (station.pm10 || station.pm25) {
        const icon = L.icon({
          iconUrl: 'cloud.png',
          iconSize: [20, 14]
        })
        const marker = L.marker([station.coordinates[1], station.coordinates[0]], { icon: icon })
          .on("click", (e) => {
            e.propagatedFrom
            if (state.station != stationId) {
              state.stationLabel = station.label;
              state.station = stationId;
            } else {
              state.station = null;
            }
          })
          .addTo(map);
        state.addStationMarker(stationId, marker);
      }
    });
    updateMarkers(state);
  })
  .catch(console.error);

loadWhoData(state);

var radios = document.querySelectorAll<HTMLInputElement>(".plottype");
radios.forEach(radio => radio.addEventListener('change', (event) => {
  const value = (event.currentTarget as HTMLInputElement).value;
  if (value == "all" || value == "average") {
    state.plotType = value as PlotType;
  }
}));
